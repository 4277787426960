import React from 'react'

export default function GoNationPoweredBy() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 250 25'
      xmlSpace='preserve'
      fill='white'
      width='250'
      height='50'>
      <g>
        <path className='st0' d='M1,6.4h1.5v10.6h5.1v1.3H1V6.4z' />
        <path
          className='st0'
          d='M16.8,14c0,3.2-2.2,4.6-4.3,4.6c-2.3,0-4.1-1.7-4.1-4.4c0-2.9,1.9-4.6,4.2-4.6C15.2,9.6,16.8,11.4,16.8,14z
            M10,14.1c0,1.9,1.1,3.3,2.6,3.3c1.5,0,2.6-1.4,2.6-3.3c0-1.5-0.7-3.3-2.6-3.3C10.8,10.8,10,12.5,10,14.1z'
        />
        <path
          className='st0'
          d='M24.9,18c-0.4,0.2-1.3,0.5-2.5,0.5c-2.6,0-4.3-1.8-4.3-4.4c0-2.6,1.8-4.6,4.6-4.6c0.9,0,1.7,0.2,2.2,0.4
           l-0.4,1.2c-0.4-0.2-1-0.4-1.8-0.4c-2,0-3,1.5-3,3.2c0,2,1.3,3.2,3,3.2c0.9,0,1.5-0.2,1.9-0.4L24.9,18z'
        />
        <path
          className='st0'
          d='M32.7,16.3c0,0.7,0,1.5,0.1,2.1h-1.4l-0.1-1.1h-0.1c-0.5,0.7-1.4,1.3-2.6,1.3c-1.7,0-2.6-1.2-2.6-2.5
           c0-2.1,1.8-3.2,5.2-3.2v-0.2c0-0.7-0.2-2-1.9-2c-0.8,0-1.6,0.2-2.2,0.6l-0.4-1c0.7-0.5,1.7-0.8,2.8-0.8c2.6,0,3.3,1.8,3.3,3.5V16.3
           z M31.2,14c-1.7,0-3.6,0.3-3.6,1.9c0,1,0.7,1.5,1.5,1.5c1.1,0,1.8-0.7,2.1-1.4c0.1-0.2,0.1-0.3,0.1-0.5V14z'
        />
        <path className='st0' d='M35.3,5.8h1.6v12.6h-1.6V5.8z' />
        <path
          className='st0'
          d='M43.2,12.6c0-1.1,0-2-0.1-2.8h1.4l0.1,1.5h0c0.6-1,1.6-1.7,3-1.7c2.1,0,3.6,1.8,3.6,4.4c0,3.1-1.9,4.6-3.9,4.6
           c-1.1,0-2.1-0.5-2.6-1.3h0v4.7h-1.5V12.6z M44.7,14.9c0,0.2,0,0.4,0.1,0.6c0.3,1.1,1.2,1.8,2.3,1.8c1.6,0,2.6-1.3,2.6-3.3
           c0-1.7-0.9-3.2-2.5-3.2c-1.1,0-2.1,0.8-2.4,1.9c-0.1,0.2-0.1,0.4-0.1,0.6V14.9z'
        />
        <path
          className='st0'
          d='M61,14c0,3.2-2.2,4.6-4.3,4.6c-2.3,0-4.1-1.7-4.1-4.4c0-2.9,1.9-4.6,4.2-4.6C59.4,9.6,61,11.4,61,14z
            M54.2,14.1c0,1.9,1.1,3.3,2.6,3.3c1.5,0,2.6-1.4,2.6-3.3c0-1.5-0.7-3.3-2.6-3.3C55,10.8,54.2,12.5,54.2,14.1z'
        />
        <path
          className='st0'
          d='M63.5,9.8l1.1,4.4c0.2,1,0.5,1.8,0.6,2.7h0.1c0.2-0.9,0.5-1.8,0.8-2.7l1.4-4.4h1.3l1.3,4.3
           c0.3,1,0.6,1.9,0.8,2.8h0.1c0.1-0.9,0.4-1.8,0.7-2.8l1.2-4.3h1.5l-2.8,8.6h-1.4l-1.3-4.1c-0.3-1-0.5-1.8-0.8-2.8h0
           c-0.2,1-0.5,1.9-0.8,2.8l-1.4,4.1h-1.4l-2.6-8.6H63.5z'
        />
        <path
          className='st0'
          d='M76.6,14.4c0,2.1,1.4,3,2.9,3c1.1,0,1.8-0.2,2.4-0.4l0.3,1.1c-0.5,0.2-1.5,0.5-2.9,0.5c-2.6,0-4.2-1.8-4.2-4.3
           c0-2.6,1.5-4.6,4-4.6c2.8,0,3.5,2.5,3.5,4c0,0.3,0,0.6-0.1,0.7H76.6z M81.2,13.2c0-1-0.4-2.5-2.2-2.5c-1.6,0-2.3,1.5-2.4,2.5H81.2z
           '
        />
        <path
          className='st0'
          d='M84.6,12.5c0-1,0-1.9-0.1-2.7h1.4l0.1,1.7H86c0.4-1.2,1.3-1.9,2.4-1.9c0.2,0,0.3,0,0.4,0.1v1.5
           c-0.2,0-0.3-0.1-0.5-0.1c-1.1,0-1.9,0.8-2.1,2c0,0.2-0.1,0.5-0.1,0.7v4.6h-1.5V12.5z'
        />
        <path
          className='st0'
          d='M91.1,14.4c0,2.1,1.4,3,2.9,3c1.1,0,1.8-0.2,2.4-0.4l0.3,1.1c-0.5,0.2-1.5,0.5-2.9,0.5c-2.6,0-4.2-1.8-4.2-4.3
           c0-2.6,1.5-4.6,4-4.6c2.8,0,3.5,2.5,3.5,4c0,0.3,0,0.6-0.1,0.7H91.1z M95.7,13.2c0-1-0.4-2.5-2.2-2.5c-1.6,0-2.3,1.5-2.4,2.5H95.7z
           '
        />
        <path
          className='st0'
          d='M106.5,5.8v10.4c0,0.8,0,1.6,0.1,2.2h-1.4l-0.1-1.5h0c-0.5,1-1.5,1.7-2.9,1.7c-2.1,0-3.7-1.8-3.7-4.4
           c0-2.9,1.8-4.6,3.8-4.6c1.3,0,2.2,0.6,2.6,1.3h0V5.8H106.5z M105,13.3c0-0.2,0-0.5-0.1-0.7c-0.2-1-1.1-1.8-2.2-1.8
           c-1.6,0-2.6,1.4-2.6,3.3c0,1.7,0.8,3.2,2.5,3.2c1,0,2-0.7,2.3-1.9c0.1-0.2,0.1-0.4,0.1-0.7V13.3z'
        />
        <path
          className='st0'
          d='M112.9,5.8h1.5v5.4h0c0.5-1,1.5-1.6,2.9-1.6c2.1,0,3.6,1.8,3.6,4.4c0,3.1-1.9,4.6-3.8,4.6
           c-1.2,0-2.2-0.5-2.9-1.6h-0.1l-0.1,1.4h-1.3c0-0.6,0.1-1.5,0.1-2.2V5.8z M114.4,14.9c0,0.2,0,0.4,0.1,0.6c0.3,1.1,1.2,1.8,2.3,1.8
           c1.6,0,2.6-1.3,2.6-3.3c0-1.7-0.9-3.2-2.5-3.2c-1.1,0-2.1,0.7-2.4,1.9c0,0.2-0.1,0.4-0.1,0.6V14.9z'
        />
        <path
          className='st0'
          d='M123.4,9.8l1.9,5.1c0.2,0.6,0.4,1.2,0.5,1.8h0c0.2-0.5,0.3-1.2,0.5-1.8l1.7-5h1.6l-2.3,6.1
           c-1.1,2.9-1.9,4.4-2.9,5.4c-0.8,0.7-1.5,0.9-1.9,1l-0.4-1.3c0.4-0.1,0.9-0.4,1.4-0.8c0.4-0.3,1-0.9,1.3-1.7
           c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.4l-3.2-7.9H123.4z'
        />
      </g>
      <g id='XMLID_1_'>
        <g id='XMLID_2_'>
          <g id='XMLID_24_'>
            <path
              id='XMLID_28_'
              className='st0'
              d='M148.4,7.4l0-2.4l-9.7,0c0,0-2.8-0.1-2.8,2.4l0,8.3c0,0-0.1,2.4,2.8,2.4l10,0V10l-6,0l0,2.4
                   l3.1,0v3.4l-7.1,0l0-8.3L148.4,7.4z'
            />
            <path
              id='XMLID_25_'
              className='st0'
              d='M160,7.9c0,0,1.2-0.1,2.2,0.7c0,0,0.6,0.6,0.7,1.3v6c0,0,0,1-0.5,1.5c-0.6,0.5-1,0.6-1.4,0.6
                   c-0.5,0.1-1,0.1-1,0.1h-7.1c0,0-1.3,0.1-2.1-0.5c-0.8-0.6-0.7-1.5-0.7-1.5v-5.9c0,0-0.1-0.9,0.7-1.5c0,0,0.6-0.6,1.8-0.7L160,7.9
                   z M152.9,15.8h7l0-5.5l-7,0L152.9,15.8z'
            />
          </g>
          <polygon
            id='XMLID_23_'
            className='st0'
            points='164,5.1 165.6,5.1 173.9,13.5 173.9,5.1 176.8,5.1 176.8,18.2 175.2,18.2 166.9,9.7 
               166.9,18.2 164,18.2 		'
          />
          <g id='XMLID_18_'>
            <path
              id='XMLID_20_'
              className='st0'
              d='M178,7.9h10.2c0,0,2.4,0,2.6,2.1v8.2h-10.2c0,0-2.5,0.1-2.7-2.1V14c0,0-0.1-1.8,2.2-2.1
                   c0,0,0.2,0,0.5,0h7.2v-1.6h-9.9L178,7.9z M188,14.2l-7,0l0,1.6h7V14.2z'
            />
            <path
              id='XMLID_19_'
              className='st0'
              d='M195,5.1l0,2.8l2.5,0v2.4H195v5.5l2.5,0l0,2.4h-2.7c0,0-1.4,0-2.2-0.8c0,0-0.6-0.5-0.6-1.3
                   v-11L195,5.1L195,5.1z'
            />
          </g>
          <polygon
            id='XMLID_17_'
            className='st0'
            points='198.8,7.9 201.7,7.9 201.7,18.2 198.8,18.2 		'
          />
          <g id='XMLID_3_'>
            <path
              id='XMLID_14_'
              className='st0'
              d='M212.9,7.9c0,0,1.2-0.1,2.2,0.7c0,0,0.6,0.6,0.7,1.3v6c0,0,0,1-0.5,1.5
                   c-0.6,0.5-1,0.6-1.4,0.6c-0.5,0.1-1,0.1-1,0.1h-7.1c0,0-1.3,0.1-2.1-0.5c-0.8-0.6-0.7-1.5-0.7-1.5v-5.9c0,0-0.1-0.9,0.7-1.5
                   c0,0,0.6-0.6,1.8-0.7L212.9,7.9z M205.8,15.8h7l0-5.5l-7,0L205.8,15.8z'
            />
            <path
              id='XMLID_13_'
              className='st0'
              d='M226.4,7.9c0,0,1.4,0,2.1,0.6c0.7,0.7,0.6,1.4,0.6,1.6c0,0.3,0,8,0,8h-2.9v-7.9h-6.3v7.9H217
                   V9.9c0,0-0.2-1.9,2.5-2L226.4,7.9z'
            />
            <path
              id='XMLID_12_'
              className='st0'
              d='M242.8,6c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6
                   C241,4.6,241,4.4,241,4.1c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.1,0.7-0.1
                   c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.7s0,0.5-0.1,0.7
                   c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4C243.3,5.9,243.1,6,242.8,6'
            />
            <path
              id='XMLID_11_'
              className='st0'
              d='M241,15.8l-2.2,3l-5.5,1.7l-0.4-2.5l4.8-1.6l1.5-2.6l0.9-5h-0.7l-1.5,2.6
                   c-0.3,0.5-0.9,0.6-1.3,0.3c-0.4-0.3-0.6-1-0.3-1.4l2.1-3.5l5.8,0l1.4,3l2.8,0.3c0.5,0.1,0.9,0.6,0.8,1.1c-0.1,0.6-0.5,1-1,0.9
                   l-3.8-0.4l-0.8-1.6l-0.7,3.9l3,2.1l0.8,6.7l-2.4,0.3l-0.6-5.4L241,15.8z'
            />
            <path
              id='XMLID_4_'
              className='st0'
              d='M229.9,8.5h0.1c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
                   h-0.1L229.9,8.5L229.9,8.5z M229.9,9.1V8.8h0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.2l0.1,0.1h0.1L230.2,9c0-0.1-0.1-0.1-0.1-0.1
                   c0,0,0,0-0.1,0c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2,0h-0.2v0.7
                   L229.9,9.1L229.9,9.1L229.9,9.1z M230.3,8.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
                   c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
                   c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1C230.1,8.2,230.2,8.3,230.3,8.3 M229.7,8.2
                   c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1
                   c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.2
                   c-0.1-0.1-0.2-0.1-0.3-0.1C229.9,8.1,229.8,8.2,229.7,8.2'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
