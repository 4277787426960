import React from 'react'

const Twitter = ({ color, height, width, style }) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox='0 0 100 82'
    version='1.1'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='twitter'>
      <g id='Group'>
        <g id='Group'>
          <path
            d='M100 9.61875C96.2813 11.25 92.3188 12.3313 88.1875 12.8563C92.4375 10.3188 95.6813 6.33125 97.2063 1.525C93.2438 3.8875 88.8688 5.55625 84.2063 6.4875C80.4438 2.48125 75.0813 0 69.2313 0C57.8813 0 48.7438 9.2125 48.7438 20.5063C48.7438 22.1313 48.8813 23.6938 49.2188 25.1813C32.175 24.35 17.0938 16.1813 6.9625 3.7375C5.19375 6.80625 4.15625 10.3188 4.15625 14.1C4.15625 21.2 7.8125 27.4938 13.2625 31.1375C9.96875 31.075 6.7375 30.1188 4 28.6125C4 28.675 4 28.7563 4 28.8375C4 38.8 11.1063 47.075 20.425 48.9813C18.7563 49.4375 16.9375 49.6563 15.05 49.6563C13.7375 49.6563 12.4125 49.5812 11.1688 49.3063C13.825 57.425 21.3625 63.3937 30.325 63.5875C23.35 69.0437 14.4937 72.3313 4.90625 72.3313C3.225 72.3313 1.6125 72.2563 0 72.05C9.08125 77.9063 19.8438 81.25 31.45 81.25C69.175 81.25 89.8 50 89.8 22.9125C89.8 22.0063 89.7688 21.1313 89.725 20.2625C93.7938 17.375 97.2125 13.7688 100 9.61875L100 9.61875Z'
            id='Shape'
            fill={color}
            stroke='none'
          />
        </g>
      </g>
    </g>
  </svg>
)

Twitter.defaultProps = {
  color: 'black',
  height: '16px',
  width: '16px',
}

export default Twitter
